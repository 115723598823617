import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NewsGrid, TilesGridSkeleton } from "../../molecule/news-grid/NewsGrid";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { getNewsTeaser } from "../../../lib/api/data/contentful/news";
import { defaultLocale } from "../../../lib/language";
import { useNews } from "../../../lib/context/news/NewsContext";
import { flattenArray } from "../../../lib/api";
import { LOCAL_NEWS_ID } from "../../../lib/api/data/contentful/news/query";

const CategoryWrapper = styled.div``;

interface NewsHomeProps {
    location: string;
}

export const NewsHome: React.FC<NewsHomeProps> = ({ location }) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { categories } = useNews();
    const [data, setData] = useState<News.Main[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getNewsByCategory().then(res => {
            setLoading(false);
            setData(
                res
                    .sort((a, b) => {
                        return (
                            Date.parse(b.sys.firstPublishedAt) - Date.parse(a.sys.firstPublishedAt)
                        );
                    })
                    .slice(0, 4)
            );
        });
    }, []);

    const getNewsByCategory = async () => {
        const res = await Promise.all(
            Object.keys(categories).map(category => {
                const categoryId = categories[category];
                const isLocal = categoryId === LOCAL_NEWS_ID;

                return getNewsTeaser(
                    {
                        location: isLocal ? location : undefined,
                        category: categoryId,
                        locale: router.locale ?? defaultLocale,
                    },
                    router.isPreview
                );
            })
        );

        return flattenArray<News.Main>(
            (res.filter(collection => collection !== null) as News.MainCollection[]).map(
                item => item.newsMainCollection.items
            )
        );
    };

    const handleSelectCategory = () => router.push("/news");

    if (!data || loading) return <TilesGridSkeleton amount={4} />;

    return (
        <CategoryWrapper>
            <NewsGrid news={data} title={t("news")} onSelectCategory={handleSelectCategory} />
        </CategoryWrapper>
    );
};
