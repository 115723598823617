import React from "react";
import styled from "styled-components";
import Image from "next/image";
import { aspectRatio } from "../../../css/content";

const Tile = styled.div`
    width: 100%;
    border-radius: 0.8rem;
    background-color: ${p => p.theme.gray50};
    ${aspectRatio(0.8)};
    transition: transform 0.3s, box-shadow 0.3s;
    will-change: transform, box-shadow;
    backface-visibility: hidden;

    @media (hover: hover) {
        &:hover {
            box-shadow: ${p => p.theme.cardShadow};
            transform: scale(1.025) translateZ(0);
        }
    }
`;

const TileInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TileLogoWrapper = styled.div`
    position: relative;
    max-width: 5rem;
    max-height: 5rem;
    margin-bottom: 1rem;

    @media ${p => p.theme.bp.m} {
        max-width: 7rem;
        max-height: 7rem;
    }
`;

const TileName = styled.div`
  max-width: 80%;
  text-align: center;
  word-break: break-word;
`;

export const AppTile: React.FC<Common.App> = ({ image, name, width, height }) => {
    return (
        <Tile>
            <TileInner>
                <TileLogoWrapper>
                    <Image src={image} alt={name} width={width} height={height} unoptimized />
                </TileLogoWrapper>
                <TileName>{name}</TileName>
            </TileInner>
        </Tile>
    );
};

export const AppTileSkeleton = () => {
    return <Tile />;
};
