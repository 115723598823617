import React from "react";
import styled from "styled-components";
import { Meta } from "../lib/meta";
import { AppGrid, AppGridSkeleton } from "../layout/molecule/app-grid/AppGrid";
import { staticApps } from "../lib/apps";
import { useSession } from "../lib/auth/client/SessionContext";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLocale } from "../lib/language";
import { NewsHome } from "../layout/templates/news-home/NewsHome";
import { TilesGridSkeleton } from "../layout/molecule/news-grid/NewsGrid";
import { Content } from "../css/content";
import { getStaticPageOrganization } from "../lib/api/organization";
import { getNewsFilters } from "../lib/api/data/contentful/news";
import { NextPageWithLayout } from "../type/app";
import { Template } from "../layout/templates/template/Template";

const HomeWrapper = styled.div`
    padding: 4rem 0;
`;

const HomeGroup = styled.div`
    margin-bottom: 6rem;

    &:last-child {
        margin: 0;
    }
`;

const Home: NextPageWithLayout = () => {
    const { session } = useSession();

    return (
        <HomeWrapper>
            <Meta />
            <HomeGroup>
                <Content>
                    {session ? (
                        <NewsHome location={session.location} />
                    ) : (
                        <TilesGridSkeleton amount={4} />
                    )}
                </Content>
            </HomeGroup>
            <HomeGroup>
                {session ? (
                    <AppGrid
                        apps={staticApps.filter(
                            app => !app.locations || app.locations.includes(session?.location)
                        )}
                    />
                ) : (
                    <AppGridSkeleton />
                )}
            </HomeGroup>
        </HomeWrapper>
    );
};

Home.getLayout = page => {
    return <Template>{page}</Template>;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const facets = await getNewsFilters(locale);
    const organization = await getStaticPageOrganization();

    return {
        props: {
            facets,
            organization,
            ...(await serverSideTranslations(locale ?? defaultLocale, ["common"])),
        },
        revalidate: 60 * 60 * 24,
    };
};

export default Home;
