import React from "react";
import styled from "styled-components";
import { AppTile, AppTileSkeleton } from "../../atom/app-tile/AppTile";
import { Content } from "../../../css/content";
import { HeadingM } from "../../../css/typography";

const GridTitle = styled.div`
    margin-bottom: 1rem;
`;

const TilesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.8rem;

    @media ${p => p.theme.bp.m} {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
`;

const TileWrapper = styled.a``;

export interface AppGridProps {
    apps: Common.App[];
}

export const AppGrid: React.FC<AppGridProps> = ({ apps }) => {
    return (
        <Content>
            <GridTitle>
                <HeadingM>Apps</HeadingM>
            </GridTitle>
            <TilesGrid>
                {apps.map((app, index) => (
                    <TileWrapper
                        key={index}
                        href={app.link}
                        target="_blank"
                        rel="noreferrer noopener">
                        <AppTile {...app} />
                    </TileWrapper>
                ))}
            </TilesGrid>
        </Content>
    );
};

export const AppGridSkeleton: React.FC = () => {
    return (
        <Content>
            <GridTitle>
                <HeadingM>Apps</HeadingM>
            </GridTitle>
            <TilesGrid>
                {[...Array(12)].map((item, index) => (
                    <AppTileSkeleton key={index} />
                ))}
            </TilesGrid>
        </Content>
    );
};
